import React from 'react'
import {GrFacebook, GrInstagram} from 'react-icons/gr';
import { Link } from 'gatsby';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container mx-auto h-full">
                <div className="flex h-full flex-wrap">
                    <div className="footer__col">
                        <h5>Visita il nostro negozio online:</h5>
                        <a href="https://neonail.it/" target="_blank" rel="noopener noreferrer">
                            <img className="h-10" src={require("../../images/logo.png")} alt="" />
                        </a>
                    </div>
                    <div className="footer__col">
                        <h5>Seguici su:</h5>
                        <div className="footer__social">
                            <a href="https://www.facebook.com/NEONAILItalia" target="_blank" rel="noopener noreferrer">
                                <figure className="footer__ico">
                                    <GrFacebook />
                                </figure>
                            </a>
                            <a href="https://www.instagram.com/neonailitalia/?hl=pl" target="_blank" rel="noopener noreferrer">
                                <figure className="footer__ico">
                                    <GrInstagram />
                                </figure>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
