import React from "react"
import Header from "./layout/Header"
import Footer from "./layout/Footer"
import CookieConsent from "react-cookie-consent";

const Layout = ({ children }) => {
  return (
    <main>
      <Header />
      <section className="content">{children}</section>
      <Footer />
      <CookieConsent
          location="bottom"
          buttonText="Vedo"
          style={{ background: "rgba(0,0,0, .75)" }}
          >
        Il nostro sito web utilizza i cookie. Utilizzando il sito, acconsenti alla loro registrazione o utilizzo. Per ulteriori informazioni, consultare la nostra <a style={{textDecoration: "underline"}} href="https://neonail.it/informativa-sulla-privacy-del-negozio.html" target="_blank">Informativa sulla privacy</a>.
      </CookieConsent>
    </main>
  )
}

export default Layout
